import React, { Component } from 'react'
import './footer.css'
import Gulmoharsvg2 from '../../Images/Gulmoharsvg2.svg'
import FooterVector1 from '../../Images/FooterVector1.svg'
import FooterVector2 from '../../Images/FooterVector2.svg'
import FooterVector3 from '../../Images/FooterVector3.svg'
import Vector1 from '../../Images/Vector1.svg'
import Vector2 from '../../Images/Vector2.svg'
import Vector3 from '../../Images/Vector3.svg'


class Footer extends Component{
    render() {
        return(
            <div className="Footer">
                <footer>
                   <img src={Gulmoharsvg2} className="Footer-logo" />
                   <div className="Social-media-icons">
                       <a href="https://www.facebook.com/Gulmohar-Spaces-115563806656499/" target="_blank"><img src={FooterVector1} /></a>
                       <a href="https://in.pinterest.com/gulmoharspaces/" target="_blank"><img src={FooterVector2} /></a>
                       <a href="https://www.instagram.com/gulmohar_spaces/" target="_blank"><img src={FooterVector3} /></a>
                   </div>

                   <div className="row footer-row">
                       <div className="col-md-4">
                            <p>
                                <a class="mobilesOnly" href="tel:+919108222498"  style={{color:"white"}}><img src={Vector1} className="F-vector1"/> +91 9108222498 </a>
                            </p>
                       </div>
                       <div className="col-md-4">
                            <p>
                                <a href="mailto:<nowiki>support@gulmoharspaces@gmail.com?" subject="HTML Link" target="_blank" style={{color:"white"}}><img src={Vector2} className="F-vector1"/> gulmoharspaces@gmail.com</a>
                            </p>
                        </div>
                        <div className="col-md-4">
                            <p>
                                <a href="https://wa.me/919916992418" target="_blank"><img src={Vector3} className="F-vector1" /><span style={{color:"white"}}>+91 9108222498</span></a>
                            </p>
                        </div> 
                   </div>

               
                
                </footer>
            </div>
        );
    }
}

export default Footer