import React, { Component } from 'react'
import Header from '../../Components/Header'
import Footer from '../../Components/Footer'
import './contact.css'

class Contact extends Component{
    render() {
        return(
            <div id="site-wrapper">
                <Header />
                    <div id="page-content">
                        <div className="Contact-page-banner">
                            <h1>Contact Us</h1>
                        </div>
                        <div className="Contact-section">
                            <div className="row contact-row">
                                <div className="col-md-6" style={{position:"relative"}}>
                                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3887.8392252937797!2d77.6375083148221!3d12.982132990848966!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0!2zMTLCsDU4JzU1LjciTiA3N8KwMzgnMjIuOSJF!5e0!3m2!1sen!2sin!4v1602136159603!5m2!1sen!2sin"  frameborder="0" style={{border:"0"}} allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
                          
                                </div>

                                <div className="col-md-6">
                                    <div className="right-contact-text">
                                        <h3>follow us</h3>
                                        <div style={{float:"left",marginLeft:"-15px"}}>
                                            <a href="https://www.facebook.com/Gulmohar-Spaces-115563806656499/" target="_blank"><i class="fab fa-facebook"></i></a>
                                            <a href="https://www.instagram.com/gulmohar_spaces/" target="_blank"><i class="fab fa-instagram-square"></i></a>
                                            <a href="https://in.pinterest.com/gulmoharspaces/" target="_blank"><i class="fab fa-pinterest"></i></a>
                                            
                                        </div>
                                        <div className="contact-details">
                                            <h3>contact us</h3>
                                            <a class="mobilesOnly" href="tel:+919108222498"><p><span><i class="fas fa-phone-alt"></i></span> +91 9108222498</p></a>
                                            <a href="https://wa.me/919108222498" target="_blank"><p><span><i class="fab fa-whatsapp-square"></i></span> +91 9108222498</p></a>
                                            <a href="mailto:<nowiki>support@gulmoharspaces@gmail.com?" subject="HTML Link" target="_blank"><p><span><i class="fas fa-envelope"></i></span> gulmoharspaces@gmail.com</p></a>
                                        </div>
                                    </div>
                               
                                </div>
                            </div>
                            
                        </div>
                    </div>
                <Footer />
            </div>
        );
    }
}

export default Contact