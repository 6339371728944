import React, { Component } from 'react'
import './about.css'
import Header from '../../Components/Header'
import Footer from '../../Components/Footer'
import WorkBanner from '../../Images/WorkBanner.png'

class About extends Component{
    render() {
        return(
            <div id="site-wrapper">
                <Header />
                    <div id="page-content">
                        <div className="about-page-banner">
                            <h1>About Us</h1>
                        </div>
                        <div className="about-page-content">
                            <p style={{marginTop: "5%"}}>
                                Gulmohar is born out of love for people and design. There is a deep 
                                relationship between our physical environment and our mental well being. Our spaces have the 
                                ability to sooth us, make our day to day life simpler and our interactions more fulfilling. 
                                We, at Gulmohar understand these nuances as the purpose of what we do.
                            </p>
                            <p>
                                We aim to create spaces that are meaningful, functional and aesthetic. We love creative endeavors 
                                and always keep our users at the center of it all. Our dedication to understand our clients better, 
                                our will to always think from scratch and our skill to use constraints as creative hurdles, 
                                is what makes us unique and keeps our work authentic. 
                            </p>
                            <p>
                                A researcher at heart and a product designer by training, Priya Choudhary started this venture with the intention 
                                to not just create good looking spaces but also to change people's perspective about the process of interior design. 
                                We like designing "with" our users and not just "for" them. We enable our clients to see this exercise an enjoyable 
                                creative experience, which they are a part of.
                            </p>

                            <h1>More about the founder</h1>
                            <p>
                                Priya graduated from NIFT, Bangalore in 2016 and started her own venture after a short but 
                                incredible corporate journey. She has been painting since she was five and decided to 
                                pursue a creative career full time during her high school days, with great support from 
                                her family and her teachers. She has a deep interest in art & culture and in understanding 
                                it's impact on society.
                            </p>
                            <p>
                                If you're interested to know more about our journey or work with us 
                                please feel free to write to us. We would be delighted! :)
                            </p>
                        </div>
                    </div>
                <Footer />
            </div>
        );
    }
}

export default About