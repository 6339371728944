import React, { Component } from 'react'
import Header from '../../../Components/Header'
import Footer from '../../../Components/Footer'
import { Link } from 'react-router-dom'
import Digi1 from '../../../Images/Digi1.jpg'
import Digi2 from '../../../Images/Digi2.jpg'
import Digi3 from '../../../Images/Digi3.jpg'
import Digi4 from '../../../Images/Digi4.jpg'
import Digi5 from '../../../Images/Digi5.jpg'
import Digi6 from '../../../Images/Digi6.jpg'
import Digi7 from '../../../Images/Digi7.jpg'
import Digi8 from '../../../Images/Digi8.jpg'

class DigitalOffice extends Component{
    componentDidMount = () =>{
        const lightbox = document.createElement('div')
        lightbox.id = 'lightbox'
        document.body.appendChild(lightbox)

        const images = document.querySelectorAll('#img')
        images.forEach(image => {
            image.addEventListener('click', e => {
                lightbox.classList.add('active')
                const img = document.createElement('img')
                img.src = image.src
                while (lightbox.firstChild) {
                    lightbox.removeChild(lightbox.firstChild)
                }
                lightbox.appendChild(img)
            })
        })
        lightbox.addEventListener('click', e => {
            lightbox.classList.remove('active')
        })
     
    }
    render() {
        return(
            <div id="site-wrapper">
                <Header />
                    <div id="page-content">
                        <div className="back-btn-section">
                            <Link to="/Work" className="a-tag"><p className="back-btn">Back</p></Link>
                        </div>


                        <div className="Project-gallery">
                            <div className="row gallery-row2">
                                <div className="column Landscape1">
                                    <div>
                                        <img src={Digi1} id="img" />
                                    </div>
                                    <div>
                                        <img src={Digi2} id="img"/>
                                    </div>
                                    <div>
                                        <img src={Digi3} id="img"/>
                                    </div>
                                    <div>
                                        <img src={Digi4} id="img"/>
                                    </div>
                                </div>
                                <div className="column Landscape1">
                                    <div>
                                        <img src={Digi5} id="img"/>
                                    </div>

                                    <div>
                                        <img src={Digi6} id="img"/>
                                    </div>

                                    <div>
                                        <img src={Digi7} id="img"/>
                                    </div>

                                    <div>
                                        <img src={Digi8} id="img"/>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                <Footer />
            </div>
        );
    }
}

export default DigitalOffice