import React, { Component } from 'react'
import './header.css'
import logo from  '../../Images/logo.svg'
import { Link } from 'react-router-dom'

class Header extends Component{

    render() {
        return(
            <div>
                <nav class="navbar  navbar-expand-lg navbar-light" id="myHeader">
                    <a className="navbar-brand" href="#"><img src={logo} /></a>
                    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span class="navbar-toggler-icon"></span>
                    </button>

                    <div class="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul class="navbar-nav ml-auto">
                            <li class="nav-item">
                                <Link class="nav-link" to="/Work">Work</Link>
                            </li>
                            <li class="nav-item">
                                <Link class="nav-link" to="/Process">Process</Link>
                            </li>
                            <li class="nav-item">
                                <Link to="/Products" class="nav-link" >Products</Link>
                            </li>
                            <li class="nav-item">
                                <Link class="nav-link" to="/About">About</Link>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" href="https://gulmoharspace.blogspot.com/">Blog</a>
                            </li>
                            <li class="nav-item">
                                <Link class="nav-link" to="/Contact">Contact</Link>
                            </li>
                        </ul>
                    </div>
                </nav>
            </div>
        )
    }
}


export default Header