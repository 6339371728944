import React, { Component } from 'react'
import Header from '../../Components/Header'
import Footer from '../../Components/Footer'

class Blog extends Component{
    render() {
        return(
            <div id="site-wrapper">
                <Header />
                    <div id="page-content">

                    </div>
                <Footer />
            </div>
        )
    }
}

export default Blog