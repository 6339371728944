import React, { Component } from 'react'
import Header from '../../Components/Header'
import Footer from '../../Components/Footer'
import './product.css'

class Products extends Component{
    render() {
        return(
            <div id="site-wrapper">
                <Header />
                    <div id="page-content">
                        <div className="Product-page-banner">
                            <h1>Coming Soon</h1>
                        </div>

                        <p className="Product-page-text">
                            Thank you for being here! We appreciate your interest in our products. 
                            We are in the process of bringing to you some unique pieces in the areas of 
                            furniture and decor. Watch this space for more!
                        </p>

                    </div>
                <Footer />
            </div>
        );
    }
}

export default Products