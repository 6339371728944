import React, { Component } from 'react'
import Header from '../../../Components/Header'
import Footer from '../../../Components/Footer'
import { Link } from 'react-router-dom'
import Pro2P1 from '../../../Images/Pro2P1.JPG'
import Pro2P2 from '../../../Images/Pro2P2.jpg'
import Pro2P3 from '../../../Images/Pro2P3.jpg'
import Pro2P4 from '../../../Images/Pro2P4.jpg'
import Pro2P5 from '../../../Images/Pro2P5.jpg'
import Pro2P6 from '../../../Images/Pro2P6.jpg'


class Lahelahe extends Component{
    componentDidMount = () =>{
        const lightbox = document.createElement('div')
        lightbox.id = 'lightbox'
        document.body.appendChild(lightbox)

        const images = document.querySelectorAll('#img')
        images.forEach(image => {
            image.addEventListener('click', e => {
                lightbox.classList.add('active')
                const img = document.createElement('img')
                img.src = image.src
                while (lightbox.firstChild) {
                    lightbox.removeChild(lightbox.firstChild)
                }
                lightbox.appendChild(img)
            })
        })
        lightbox.addEventListener('click', e => {
            lightbox.classList.remove('active')
        })
     
    }
    render() {
        return(
            <div id="site-wrapper">
                <Header />
                    <div id="page-content">
                        <div className="back-btn-section">
                            <Link to="/Work" className="a-tag"><p className="back-btn">Back</p></Link>
                        </div>

                        <div className="Project-gallery">
                            <div className="row gallery-row">
                                <div className="column Potriate">
                                    <div>
                                        <img src={Pro2P1} id="img"/>
                                    </div>
                                    <div>
                                        <img src={Pro2P2} id="img"/>
                                    </div>
                                    <div>
                                        <img src={Pro2P3} id="img" />
                                    </div>
                                   
                                </div>
                                <div className="column Potriate">
                                    <div>
                                        <img src={Pro2P4} id="img"/>
                                    </div>
                                    <div>
                                        <img src={Pro2P5} id="img"/>
                                    </div>

                                    <div>
                                        <img src={Pro2P6} id="img"/>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                <Footer />
            </div>
        );
    }
}

export default Lahelahe