import React, { Component } from 'react'
import './work.css'
import Header from '../../Components/Header'
import Footer from '../../Components/Footer'
import TwentyTwenty from 'react-twentytwenty'


import { SRLWrapper } from 'simple-react-lightbox'

import res1 from '../../Images/res1.png'
import res2 from '../../Images/res2.png'
import res3 from '../../Images/res3.png'
import res4 from '../../Images/res4.png'
import res5 from '../../Images/res5.png'
import res6 from '../../Images/res6.png'
import res7 from '../../Images/res7.png'
import res8 from '../../Images/res8.png'
import res9 from '../../Images/res9.png'
import res10 from '../../Images/res10.png'
import res11 from '../../Images/res11.png'
import res12 from '../../Images/res12.png'
import res13 from '../../Images/res13.png'
import res14 from '../../Images/res14.png'
import res15 from '../../Images/res15.png'
import com1 from '../../Images/com1.png'
import com2 from '../../Images/com2.png'
import com3 from '../../Images/com3.png'
import com4 from '../../Images/com4.png'
import com5 from '../../Images/com5.png'
import com6 from '../../Images/com6.png'
import com7 from '../../Images/com7.png'
import com8 from '../../Images/com8.png'
import com9 from '../../Images/com9.png'
import com10 from '../../Images/com10.png'
import com11 from '../../Images/com11.png'
import com12 from '../../Images/com12.png'

import A1 from '../../Images/A1.png'
import A2 from '../../Images/A2.png'
import B1 from '../../Images/B1.png'
import B2 from '../../Images/B2.png'
import C1 from '../../Images/C1.png'
import C2 from '../../Images/C2.png'
import D1 from '../../Images/D1.png'
import D2 from '../../Images/D2.png'
import E1 from '../../Images/E1.png'
import E2 from '../../Images/E2.png'
import F1 from '../../Images/F1.png'
import F2 from '../../Images/F2.png'
import G1 from '../../Images/G1.png'
import G2 from '../../Images/G2.png'
import H1 from '../../Images/H1.png'
import H2 from '../../Images/H2.png'
import I1 from '../../Images/I1.png'
import I2 from '../../Images/I2.png'
import J1 from '../../Images/J1.png'
import J2 from '../../Images/J2.png'



class Work extends Component{
    render() {
        return(
            <div id="site-wrapper">
                <Header />
                    <div id="page-content">
                        <div className="Work-page-banner" id="Work-page">
                            <h1>Our work</h1>
                        </div>

                        <div>
                        <SRLWrapper>
                            <div id="" className="lightbox-gallery">
                                <h1>Residential</h1>
                            <div className="row">
                               
                                <div className="col-md-4 gallery-images">
                                    <img src={res3} alt="" />
                                </div>
                                <div className="col-md-4 gallery-images">
                                    <img src={res4} alt="" />
                                </div>
                                <div className="col-md-4 gallery-images">
                                    <img src={res5} alt="" />
                                </div>
                                <div className="col-md-4 gallery-images">
                                    <img src={res6} alt="" />
                                </div>
                                <div className="col-md-4 gallery-images">
                                    <img src={res10} alt="" />
                                </div>
                                <div className="col-md-4 gallery-images">
                                    <img src={res7} alt="" />
                                </div>
                                <div className="col-md-4 gallery-images">
                                    <img src={res1} alt="" />
                                </div>
                                <div className="col-md-4 gallery-images">
                                    <img src={res8} alt="" />
                                </div>
                                <div className="col-md-4 gallery-images">
                                    <img src={res9} alt="" />
                                </div>
                              
                                <div className="col-md-4 gallery-images">
                                    <img src={res11} alt="" />
                                </div>
                                <div className="col-md-4 gallery-images">
                                    <img src={res12} alt="" />
                                </div>
                                <div className="col-md-4 gallery-images">
                                    <img src={res13} alt="" />
                                </div>
                                <div className="col-md-4 gallery-images">
                                    <img src={res2} alt="" />
                                </div>
                                <div className="col-md-4 gallery-images">
                                    <img src={res14} alt="" />
                                </div>
                                <div className="col-md-4 gallery-images">
                                    <img src={res15} alt="" />
                                </div>
                            </div>
                            <h1 style={{marginTop:"5%"}}>Commercial</h1>
                            <div className="row">
                                <div className="col-md-4 gallery-images">
                                    <img src={com1} alt="" />
                                </div>
                                <div className="col-md-4 gallery-images">
                                    <img src={com2} alt="" />
                                </div>
                                <div className="col-md-4 gallery-images">
                                    <img src={com3} alt="" />
                                </div>
                                <div className="col-md-4 gallery-images">
                                    <img src={com4} alt="" />
                                </div>
                                <div className="col-md-4 gallery-images">
                                    <img src={com5} alt="" />
                                </div>
                                <div className="col-md-4 gallery-images">
                                    <img src={com6} alt="" />
                                </div>
                                <div className="col-md-4 gallery-images">
                                    <img src={com7} alt="" />
                                </div>
                                <div className="col-md-4 gallery-images">
                                    <img src={com8} alt="" />
                                </div>
                                <div className="col-md-4 gallery-images">
                                    <img src={com9} alt="" />
                                </div>
                                <div className="col-md-4 gallery-images">
                                    <img src={com10} alt="" />
                                </div>
                                <div className="col-md-4 gallery-images">
                                    <img src={com11} alt="" />
                                </div>
                                <div className="col-md-4 gallery-images">
                                    <img src={com12} alt="" />
                                </div>
                            </div>
                     
                            </div>
                        </SRLWrapper>
                        </div>
                        <div className="before-after-segment">
                            <h1>Before & After</h1>
                            <p>Slide the bar to see the transformation</p>
                            <div className="after-slider">
                                <TwentyTwenty className="before-after-slider"
                                    left={<img src={A2} className="image1" />}
                                    right={<img src={A1} className="image1" />}
                                    slider={<div className="slider" />}
                                />
                            </div>

                            <div className="after-slider">
                                <TwentyTwenty className="before-after-slider"
                                    left={<img src={B2} className="image1" />}
                                    right={<img src={B1} className="image1" />}
                                    slider={<div className="slider" />}
                                />
                            </div>

                            <div className="after-slider">
                                <TwentyTwenty className="before-after-slider"
                                    left={<img src={D2} className="image1" />}
                                    right={<img src={C1} className="image1" />}
                                    slider={<div className="slider" />}
                                />
                            </div>

                            <div className="after-slider">
                                <TwentyTwenty className="before-after-slider"
                                    left={<img src={C2} className="image1" />}
                                    right={<img src={D1} className="image1" />}
                                    slider={<div className="slider" />}
                                />
                            </div>

                            <div className="after-slider">
                                <TwentyTwenty className="before-after-slider"
                                    left={<img src={E2} className="image1" />}
                                    right={<img src={E1} className="image1" />}
                                    slider={<div className="slider" />}
                                />
                            </div>

                            <div className="after-slider">
                                <TwentyTwenty className="before-after-slider"
                                    left={<img src={F2} className="image1" />}
                                    right={<img src={F1} className="image1" />}
                                    slider={<div className="slider" />}
                                />
                            </div>

                            <div className="after-slider">
                                <TwentyTwenty className="before-after-slider"
                                    left={<img src={G2} className="image1" />}
                                    right={<img src={G1} className="image1" />}
                                    slider={<div className="slider" />}
                                />
                            </div>

                            <div className="after-slider">
                                <TwentyTwenty className="before-after-slider"
                                    left={<img src={H2} className="image1" />}
                                    right={<img src={H1} className="image1" />}
                                    slider={<div className="slider" />}
                                />
                            </div>

                            <div className="after-slider">
                                <TwentyTwenty className="before-after-slider"
                                    left={<img src={I2} className="image1" />}
                                    right={<img src={I1} className="image1" />}
                                    slider={<div className="slider" />}
                                />
                            </div>

                            <div className="after-slider">
                                <TwentyTwenty className="before-after-slider"
                                    left={<img src={J2} className="image1" />}
                                    right={<img src={J1} className="image1" />}
                                    slider={<div className="slider" />}
                                />
                            </div>

                         
                        </div>
                     
                       
                   
                                        
                         
                   
                 
               
                              
                    </div>
                <Footer />
            </div>
        );
    }
}

export default Work