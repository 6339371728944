import React, { Component } from 'react'
import './process.css'
import Header from '../../Components/Header'
import Footer from '../../Components/Footer'
import process1 from '../../Images/process1.jpg'
import process2 from '../../Images/process2.jpg'
import process3 from '../../Images/process3.jpg'
import process4 from '../../Images/process4.jpg'
import process5 from '../../Images/process5.png'
import process6 from '../../Images/process6.png'
import process7 from '../../Images/process7.jpg'
import process8 from '../../Images/process8.jpg'
import whyusVector1 from '../../Images/whyusVector1.svg'
import whyusVector2 from '../../Images/whyusVector2.svg'
import whyusVector3 from '../../Images/whyusVector3.svg'
import whyusVector4 from '../../Images/whyusVector4.svg'


class Process extends Component{
    render() {
        return(
            <div id="site-wrapper">
                <Header />
                    <div id="page-content">
                        <div className="Process-Banner">
                            <h1>The Process</h1>
                        </div>
                        <div className="Process-desc">
                            <div className="Process-desc-text">
                                <p>
                                    Our projects strike a fine balance between aesthetics and function, between the user’s taste and the 
                                    global trends, and between experimentation and sticking to the norm. Our process of design therefore, 
                                    differs largely from one project to the other depending on what the focus is. However, we have tried 
                                    to bucket the various parts of the process here, for your interest and clarity.
                                </p>
                            </div>
                        </div>
                        <div className="Process-section">
                        <div className="Process1 first-process">
                            <div className="row Process1-row">
                                <div className="image-section first-image-section">
                                    <img src={process1} className="center"/>
                                    <hr className="Hr-line" />
                                </div>
                            
                                <div className="Process1-right-text One-text">
                                    <h3>First interaction</h3>
                                    <p>
                                        We meet you over a video chat or in person<br/>
                                        to understand your project, the space, the<br/>
                                        user, timelines etc.
                                    </p>
                                </div>
                            </div>
                         
                        </div>

                        <div className="Process2">
                            <div className="row Process2-row">
                                <div className="image-section2">
                                    <img src={process2} className="process2-img"/>
                                    <hr className="Hr-line2" />
                                </div>
                            
                                <div className="Process2-right-text">
                                    <h3>PRESENTATION</h3>
                                    <p>
                                        We present you with ideas and<br/> 
                                        reference images to understand your taste<br/>
                                        better and build ideas with you.
                                    
                                    </p>
                                </div>
                            </div>
                         
                        </div>

                        <div className="Process1">
                            <div className="row Process1-row">
                                <div className="image-section">
                                    <img src={process3} className="center"/>
                                    <hr className="Hr-line" />
                                </div>
                                <div className="process-pattren1">
                                    <img src={whyusVector2} />
                                </div>
                            
                                <div className="Process1-right-text">
                                    <h3>LAYOUT & MOOD BOARDS</h3>
                                    <p>
                                        We get to the drawing board and work on<br/>
                                        possible layouts and mood boards for each<br/>
                                        of the areas of your space.
                                    </p>
                                </div>
                            </div>
                         
                        </div>

                        <div className="Process2">
                            <div className="row Process2-row">
                                <div className="image-section2">
                                    <img src={process4} className="process2-img"/>
                                    <hr className="Hr-line2" />
                                </div>
                            
                                <div className="Process2-right-text">
                                    <h3>DIGITAL RENDERS</h3>
                                    <p>
                                        We present you with a final look in form of<br/>
                                        digital renders and move to site only after<br/>
                                        your approval.
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="Process1">
                            <div className="row Process1-row">
                                <div className="image-section">
                                    <img src={process5} className="center"/>
                                    <hr className="Hr-line" />
                                </div>
                                <div className="process-pattren2">
                                    <img src={whyusVector4} />
                                </div>
                            
                                <div className="Process1-right-text">
                                    <h3>MATERIAL SOURCING</h3>
                                    <p>
                                        As per the finalised design we start material<br/>
                                        selection, finalisation and sourcing for the<br/>
                                        execution work to begin.
                                    </p>
                                </div>
                            </div>
                         
                        </div>


                        <div className="Process2">
                            <div className="row Process2-row">
                                <div className="image-section2">
                                    <img src={process6} className="process2-img"/>
                                    <hr className="Hr-line2" />
                                </div>
                                <div className="process-pattren3">
                                    <img src={whyusVector1} />
                                </div>
                            
                                <div className="Process2-right-text process3-text">
                                    <h3>EXECUTION</h3>
                                    <p>
                                        We finalise vendors, place orders making<br/>
                                        sure everything happens as per the<br/>
                                        drawings or finalised design.
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="Process1 ">
                            <div className="row Process1-row">
                                <div className="image-section">
                                    <img src={process7} className="center"/>
                                    <hr className="Hr-line" />
                                </div>
                                <div className="process-pattren4">
                                    <img src={whyusVector3} />
                                </div>
                            
                                <div className="Process1-right-text">
                                    <h3>STYLING</h3>
                                    <p>
                                        We add the finer nuances in terms of decor<br/>
                                        & finishes for that final touch of warmth in<br/>
                                        your space.
                                    </p>
                                </div>
                            </div>
                         
                        </div>

                        <div className="Process2 last-process">
                            <div className="row Process2-row">
                                <div className="image-section2">
                                    <img src={process8} className="process2-img"/>
                                    <hr className="Hr-line2 Hr-line3" />
                                </div>
                            
                                <div className="Process2-right-text last-process-text">
                                    <h3>HANDOVER</h3>
                                    <p>
                                        We end it with a tiny celebration meeting<br/>
                                        where we make sure you are happy with every<br/>
                                        detail of the outcome.
                                    </p>
                                </div>
                            </div>
                        </div>
                        </div>

                        


                    </div>
                <Footer />
            </div>

        )
    }
}

export default Process