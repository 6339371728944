import React, { Component } from 'react'
import Header from '../../../Components/Header'
import Footer from '../../../Components/Footer'
import './gallery.css'
import { Link } from 'react-router-dom'
import ourwork1 from '../../../Images/ourwork1.jpg'
import Pro1L1 from '../../../Images/Pro1L1.jpg'
import Pro1L4 from '../../../Images/Pro1L4.jpg'
import Pro1L3 from '../../../Images/Pro1L3.jpg'

import Pro1P2 from '../../../Images/Pro1P2.jpg'
import Pro1P3 from '../../../Images/Pro1P3.jpg'
import Pro1P4 from '../../../Images/Pro1P4.jpg'

import Pro1L5 from '../../../Images/Pro1L5.jpg'
import Pro1L6 from '../../../Images/Pro1L6.jpg'


class Prestige extends Component{
    componentDidMount = () =>{
        const lightbox = document.createElement('div')
        lightbox.id = 'lightbox'
        document.body.appendChild(lightbox)

        const images = document.querySelectorAll('#img')
        images.forEach(image => {
            image.addEventListener('click', e => {
                lightbox.classList.add('active')
                const img = document.createElement('img')
                img.src = image.src
                while (lightbox.firstChild) {
                    lightbox.removeChild(lightbox.firstChild)
                }
                lightbox.appendChild(img)
            })
        })
        lightbox.addEventListener('click', e => {
            lightbox.classList.remove('active')
        })
     
    }
    render() {
        return(
            <div id="site-wrapper">
                <Header />
                    <div id="page-content">
                        <div className="back-btn-section">
                            <Link to="/Work" className="a-tag"><p className="back-btn">Back</p></Link>
                        </div>

                        <div className="Project-gallery">
                            <div className="row gallery-row" data-lightbox="mygallery">
                                <div className="column Potriate">
                                    <div>
                                        <img src={ourwork1} id="img" />
                                    </div>
                                    <div>
                                        <img src={Pro1P2} id="img"/>
                                    </div>
                                    <div>
                                        <img src={Pro1P3} id="img"/>
                                    </div>
                                    <div>
                                        <img src={Pro1P4} id="img"/>
                                    </div>
                                </div>
                                <div className="column Landscape">
                                    <div>
                                        <img src={Pro1L1} id="img"/>
                                    </div>

                                    <div>
                                        <img src={Pro1L4} id="img"/>
                                    </div>

                                    <div>
                                        <img src={Pro1L3} id="img"/>
                                    </div>

                                    <div>
                                        <img src={Pro1L5} id="img"/>
                                    </div>

                                    <div>
                                        <img src={Pro1L6} id="img"/>
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>
                <Footer />
            </div>
        )
    }
}


export default Prestige