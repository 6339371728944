import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css'
import SimpleReactLightbox from 'simple-react-lightbox'
import './App.css';
import { HashRouter, Route, Switch } from 'react-router-dom'
import Home from './Screens/Home'
import About from './Screens/About'
import Work from './Screens/Work'
import Prestige from './Screens/ProjectScreens/Prestige'
import Lahelahe from './Screens/ProjectScreens/Lahelahe';
import Villa from './Screens/ProjectScreens/Villa';
import DigitalOffice from './Screens/ProjectScreens/DigitalOffice';
import Process from './Screens/Process';
import Blog from './Screens/Blog';
import Contact from './Screens/Contact';
import Products from './Screens/Products';

function App() {
  return (
    <div className="App">
      <SimpleReactLightbox>
        <HashRouter>
            <Switch>
                <Route exact path="/" component={Home} />
               
                  <Route path="/Work" component={Work} />
           
                <Route path="/About" component={About} />
                <Route path="/Process" component={Process} />
                <Route path="/Products" component={Products} />
                <Route path="/Blog" component={Blog} />
                <Route path="/Contact" component={Contact} />

                <Route path="/ProjectScreens/Prestige" component={Prestige} />
                <Route path="/ProjectScreens/Lahelahe" component={Lahelahe} />
                <Route path="/ProjectScreens/Villa" component={Villa} />
                <Route path="/ProjectScreens/DigitalOffice" component={DigitalOffice} />
            </Switch>
        </HashRouter>
        </SimpleReactLightbox>
    </div>
  );
}

export default App;
