import React, { Component } from 'react'
import { HashLink as Link } from 'react-router-hash-link'

import './home.css'
import Header from '../../Components/Header'
import Footer from '../../Components/Footer'
import HomepageBanner from '../../Images/HomepageBanner.png'
import Gulmoharsvg from '../../Images/Gulmoharsvg.svg'
import LuxuryBoard from '../../Images/LuxuryBoard.jpg'
import BudgetBoard3 from '../../Images/BudgetBoard3.jpg'
import EarthyBoard2 from '../../Images/EarthyBoard2.jpg'
import CafeBoard from '../../Images/CafeBoard.jpg'
import OfficeSpaces from '../../Images/OfficeSpaces.jpg'
import Gulmoharlotus1 from '../../Images/Gulmoharlotus1.svg'
import Gulmoharlotus2 from '../../Images/Gulmoharlotus2.svg'
import whyusVector1 from '../../Images/whyusVector1.svg'
import whyusVector2 from '../../Images/whyusVector2.svg'
import whyusVector3 from '../../Images/whyusVector3.svg'
import ourwork1 from '../../Images/ourwork1.jpg'
import ourwork2 from '../../Images/ourwork2.jpg'
import ourwork3 from '../../Images/ourwork3.jpg'
import ourwork4 from '../../Images/ourwork4.jpg'
import ourwork5 from '../../Images/ourwork5.jpg'
import ourwork6 from '../../Images/ourwork6.jpg'
import ourwork7 from '../../Images/ourwork7.jpg'
import ourwork8 from '../../Images/ourwork8.jpg'
import ourwork9 from '../../Images/ourwork9.jpg'
import FollowIcon1 from '../../Images/FollowIcon1.svg'
import FollowIcon2 from '../../Images/FollowIcon2.svg'
import FollowIcon3 from '../../Images/FollowIcon3.svg'

import Pattern1 from '../../Images/Pattren1.svg'
import Pattren2 from '../../Images/Pattren2.svg'

import OurWorkLotus1 from '../../Images/OurWorkLotus1.svg'
import OurWorkLotus2 from '../../Images/OurWorkLotus2.svg'


import TestimonialsLotus1 from '../../Images/TestimonialsLotus1.svg'
import TestimonialsLotus2 from '../../Images/TestimonialsLotus2.svg'





class Home extends Component{
  
    render() {
        return(
            <div id="site-wrapper">
                <Header/>
                    <div id="page-content">
                        <div className="Homepage-banner">
                            <img src={HomepageBanner} />
                            <h1>Beauty . Comfort . Peace</h1>
                        </div>
                        <div>
                            <img src={Pattern1} className="Pattren1"/>
                        </div>
                        
                       
                        <div className="home-about">
              
                            <div className="home-about-text">
                                <p>
                                    The things that we fall for are not always of a certain theme or a certain 
                                    color but actually are a beautiful mish mash of our childhood memories, 
                                    our present musings and our hope for the future.
                                </p>
                                <img src={Gulmoharsvg} className="Gulmoharsvg"/>
                            </div>
                        </div>
                        
                        <div classdName="" style={{height:"40px"}}>
                            <img src={Pattren2} className="Pattren2"/>
                        </div>

                        <div className="Services-section">
                            <div id="carouselExampleIndicators" className="carousel slide" data-ride="carousel" data-interval="false">
                                <ol class="carousel-indicators">
                                    <li data-target="#carouselExampleIndicators" data-slide-to="0" class="active"></li>
                                    <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
                                    <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
                                    <li data-target="#carouselExampleIndicators" data-slide-to="3"></li>
                                    <li data-target="#carouselExampleIndicators" data-slide-to="4"></li>
                                </ol>
                                <div class="carousel-inner">
                                    <div class="carousel-item active">
                                        <div className="row carousel-row">
                                            <div className="col-md-6 carousel-left">
                                                <div className="carousel-left-text">
                                                    <h2>we make</h2>
                                                    <h1>Luxurious<br/>Homes</h1>
                                                    <p>
                                                        We love understanding our users and
                                                        creating interior spaces that are uniquely
                                                        theirs. We customize and put together your
                                                        taste in an artistic fashion to give you
                                                        something you’d be proud to own.
                                                    </p>
                                                </div>
                                            
                                            </div>
                                            <div className="col-md-6 carousel-right">
                                                <img src={LuxuryBoard} class="center" alt="..." />
                                            </div>
                                        </div>
                                       
                                    </div>
                                    <div class="carousel-item">
                                        <div className="row carousel-row">
                                            <div className="col-md-6  carousel-left">
                                                <div className="carousel-left-text">
                                                    <h2>we make</h2>
                                                    <h1>Budget<br/>Homes</h1>
                                                    <p>
                                                        Good looking homes don't always have to come at a huge price. 
                                                        We enjoy putting together good quality, functional, 
                                                        aesthetic work in the limited budgets as well.
                                                    </p>
                                                </div>
                                              
                                            </div>
                                            <div className="col-md-6 carousel-right">
                                                <img src={BudgetBoard3} class="center" alt="..." />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="carousel-item">
                                        <div className="row carousel-row">
                                            <div className="col-md-6  carousel-left">
                                                <div className="carousel-left-text">
                                                    <h2>we make</h2>
                                                    <h1>Earthy<br/>Spaces</h1>
                                                    <p>
                                                        We love sticking to sustainable ideas and 
                                                        delivering interiors that are not just extremely good looking 
                                                        but also have a story to them, are kind to the environment and 
                                                        support local communities.
                                                    </p>
                                                </div>
                                              
                                            </div>
                                            <div className="col-md-6 carousel-right">
                                                <img src={EarthyBoard2} class="center" alt="..." />
                                            </div>
                                        </div>
                                    </div>

                                    <div class="carousel-item">
                                        <div className="row carousel-row">
                                            <div className="col-md-6  carousel-left">
                                                <div className="carousel-left-text">
                                                    <h2>we make</h2>
                                                    <h1>Concept<br/>Cafes</h1>
                                                    <p>
                                                        Interiors contribute largely to the vibes of a cafe or restaurant space. 
                                                        We design spaces that are unique, comfortable and inviting for the particular 
                                                        audience that you're trying to serve.
                                                    </p>
                                                </div>
                                              
                                            </div>
                                            <div className="col-md-6 carousel-right">
                                                <img src={CafeBoard} class="center" alt="..." />
                                            </div>
                                        </div>
                                    </div>

                                    <div class="carousel-item">
                                        <div className="row carousel-row">
                                            <div className="col-md-6  carousel-left">
                                                <div className="carousel-left-text">
                                                    <h2>we make</h2>
                                                    <h1>Inspiring<br/>offices</h1>
                                                    <p>
                                                        We design offices with as much zeal as one puts in designing a comfortable 
                                                        home or a vibrant cafe. We give you not just a great place to come and work 
                                                        at but also something that contributes to the culture of your company.
                                                    </p>
                                                </div>
                                              
                                            </div>
                                            <div className="col-md-6 carousel-right">
                                                <img src={OfficeSpaces} class="center" alt="..." />
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <a className="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
                                    <img src={Gulmoharlotus1} className="Gulmoharlotus"/>
                                </a>
                                <a className="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
                                   <img src={Gulmoharlotus2} className="Gulmoharlotus" />
                                </a>
                            </div>
                        </div>

                        {/* Why us Section */}

                        <div className="whyus-section">
                            <div className="whyus-section-content">
                                <h1>why us</h1>
                                <div className="row whuys-row">
                                    <div className="col-md-4 whyus-row-text">
                                        <img src={whyusVector1} />
                                        <h2>we understand you</h2>
                                        <p>
                                            We take keen interest in understanding our<br/> clients, our users and their expectations<br/> from their space. 
                                            We take note of your<br/> smallest comfort spots and weave them<br/> into your space seamlessly.
                                        </p>
                                    </div>

                                    <div className="col-md-4 whyus-row-text">
                                        <img src={whyusVector2} />
                                        <h2>we think fresh</h2>
                                        <p>
                                            What fits one space perfectly does not<br/> necessarily work that well 
                                            for another.<br/> That’s why we like thinking from scratch<br/> for each 
                                            of our projects,to incorporate<br/> new trends and ideas everytime.

                                        </p>
                                    </div>

                                    <div className="col-md-4 whyus-row-text">
                                        <img src={whyusVector3} />
                                        <h2>we love constraints</h2>
                                        <p>
                                          

                                            Every project is unique in terms of its<br/> timelines, space and budget. 
                                            We believe<br/> challenges to be healthy and we thrive<br/> on them to come up with breakthrough<br/> ideas. 
                                            Thus, allowing us to build<br/> your dream space.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Our Work Section */}

                        <div className="ourwork-section">
                            <div className="ourwork-section-content">
                                <h1><span><img src={OurWorkLotus1} className="ourwork-icons" /> </span> our work <span><img src={OurWorkLotus2} className="ourwork-icons" /> </span></h1>
                                <div className="ourwork-images">
                                    <img src={ourwork1} />
                                    <img src={ourwork2} />
                                    <img src={ourwork3} />
                                    <img src={ourwork4} />
                                    <img src={ourwork5} />
                                    <img src={ourwork6} />
                                    <img src={ourwork7} />
                                    <img src={ourwork8} />
                                    <img src={ourwork9} />
                                </div>
                                <div>
                                <Link to="/Work#Work-page"><button type="button" className="btn view-more-btn">View More</button></Link>
                                </div>
                             
                                
                            </div>
                        </div>

                        {/* Testimonial Section */}

                        <div className="Testimonial-section">
                            <h1>Clients speak</h1>
                            <div id="carouselExampleControls" className="carousel slide Testimonial-carousel" data-ride="carousel">
                                <div class="carousel-inner Testimonial-carousel-inner">
                                    <div className="carousel-item active testimonial-text">
                                        <p>
                                            I was working abroad while my project was going on but I could trust them completely. 
                                            They are very diligent and it shows in their work. 
                                        </p>
                                        <h5>
                                            Vikram Nangia<br/>
                                            Phoenix One Bangalore West
                                        </h5>
                                    </div>

                                    <div class="carousel-item testimonial-text">
                                        <p>
                                            They have a great sense of design.The home has been left spacious while every element 
                                            that we wanted is present. Ultimately the capacity to listen and the intent to 
                                            really deliver is unbeatable.
                                        </p>
                                        <h5>
                                            Ritu & Ravi<br/>
                                            Prestige Ferns Residency
                                        </h5>
                                    </div>

                                    <div className="carousel-item testimonial-text">
                                        <p>
                                            Walking into Lahe Lahe Santhe, the energy of the space immediately puts you at ease. 
                                            There are interesting elements to be found in every corner and it is evident that 
                                            everything present has been thoughtfully curated.
                                        </p>
                                        <h5>
                                            Siddharth Chaitanya, Patron<br/>
                                            Lahe Lahe Cafe
                                        </h5>
                                    </div>

                                </div>
                                <a class="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
                                    <img src={TestimonialsLotus1} className="TestimonialsLotus"/>
                                </a>
                                <a class="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
                                    <img src={TestimonialsLotus2}  className="TestimonialsLotus"/>
                                </a>
                            </div>
                        </div>

                        {/* Follow us  */}

                        <div className="Followus-section">
                            <h1>follow us</h1>
                            <p>Stay up-to-date with our latest musings !</p>
                            <div className="followsus-icons">
                                <a href=" https://www.facebook.com/Gulmohar-Spaces-115563806656499/" target="_blank"><img src={FollowIcon1} /></a>
                                <a href="https://www.instagram.com/gulmohar_spaces/" target="_blank"><img src={FollowIcon2} /></a>
                                <a href="https://in.pinterest.com/gulmoharspaces/" target="_blank"><img src={FollowIcon3} /></a>
                            </div>
                          
                       <div class="powr-instagram-feed" id="166c4c91_1601020248" ></div>
                        </div>


                    </div>
                <Footer />
            </div>
        );
    }
}

export default Home